<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="text-white"><b> Registro de Novedades</b></h4>
          </template>
          <div class="card-body">
            <h4 class="card-title text-white mb-3"><b>Trabajador</b></h4>
            <b-form @submit.prevent="searchingWorker"> </b-form>

            <!-- Si existe el trabajador se mostrara la siguiente informacion -->
            <article v-if="worker.about" class="mt-4">
              <!-- <h4 class="my-3">Información del trabajador</h4> -->
              <b-list-group class="info-worker my-3">
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-2">{{ worker.about.apellidos }} {{ worker.about.segundoApellido }} {{ worker.about.nombres }} {{ worker.about.segundoNombre }}</h4>
                  </div>
                  <div class="mt-2">
                    <strong>Cédula de ciudadanía: </strong>{{ worker.about.numeroDocumento }}

                    <strong class="ml-3">Salario: </strong
                    >{{
                      Number(worker.about.salario).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                    <strong class="ml-3">Fecha contratación: </strong
                    >{{ 
                      $moment.tz(worker.about.fechaContratacion, "America/Bogota").format("DD-MM-YYYY"),

                    }}
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div class="table-responsive px-4 mt-3">
                <table id="datatablenovedades" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Novedad</th>
                      <th>Valor</th>
                      <th>Fecha Registro</th>
                      <th>Fecha inicio</th>
                      <th>Fecha Fin</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in incapacidades" :key="item.id">
                      <template v-if="(item.conceptoId > 3 && item.tipoNovedadId == 1) || (item.conceptoId > 2 && item.conceptoId < 46 && item.tipoNovedadId == 2)">
                        <td>{{ item.novedad }}</td>
                        <td>${{ item.tipoNovedadId == 1 ? item.tDed.toFixed(2) : item.tDev.toFixed(2) }}</td>
                        <td>{{ $moment.tz(item.fechaRegistro, "America/Bogota").format("DD-MM-YYYY HH:mm") }}</td>
                        <td>{{ $moment.tz(item.fechaInicio, "America/Bogota").format("DD-MM-YYYY HH:mm") }}</td>
                        <td>{{ $moment.tz(item.fechaFin, "America/Bogota").format("DD-MM-YYYY HH:mm") }}</td>
                        <td>
                          <!-- <span class="pointer"><i @click="editRow(item)" class="fas fa-solid fa-pencil px-1 text-success mr-1"></i> </span> -->
                          <span class="pointer"><i @click="deleteRow(item)" class="fas fa-solid fa-trash px-1 text-danger"></i> </span>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </card>
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-white"><b>Registrar novedad</b></h4>
            {{ newForm.tipoDeEvento }}
          </template>
          <div class="card-body">
            <article class="nueva-incapacidad">
              <div class="d-flex justify-content-center">
                <vs-button style="width: 200px" animation-type="vertical" success   icon class="" v-show="!hasForm" @click="createForm" :disabled="!worker.about"
                  ><i class="fas fa-plus pr-1"></i> Agregar nueva novedad<template #animate> <i class="fas fa-plus"></i></template
                ></vs-button>
                <vs-button style="width: 200px" animation-type="vertical" danger   icon v-show="hasForm" @click="cancelForm"
                  ><i class="fas fa-xmark pr-1"></i>Cancelar<template #animate> <i class="fas fa-xmark"></i></template
                ></vs-button>
              </div>
            </article>
            <!-- <b-form v-if="hasForm" @submit.prevent="guardarDataUsuario()" class="my-4"> -->
            <b-form v-if="hasForm" @submit.prevent="guardarDataUsuario()" class="my-4">
              <div class="row">
                <!-- tipo de evento -->
                <b-form-group label-for="tipoDeEvento" label="Tipo de evento" class="col-md-3">
                  <b-form-select id="tipoDeEvento" v-model="newForm.TipoNovedadId" :options="tipoDeEvento" required></b-form-select>
                </b-form-group>

                <div v-if="newForm.TipoNovedadId == 1" class="col-md-3">
                  <label for=" " class="form-label">Tipo de deducción</label>
                  <b-form-select
                    v-model="newForm.conceptoId"
                    required
                    @change="selecionarItem($event, 'deduccion')"
                    value-field="id"
                    text-field="descripcion"
                    :options="listaDeducciones"
                  ></b-form-select>
                </div>
                <div class="col-md-3" v-if="newForm.TipoNovedadId == 2">
                  <label for=" " class="form-label">Tipo de devengado</label>
                  <b-form-select
                    v-model="newForm.conceptoId"
                    required
                    @change="
                      selecionarItem($event, 'devengado');
                      diffDate();
                    "
                    value-field="id"
                    text-field="descripcion"
                    :options="listaDevengados"
                  ></b-form-select>
                </div>

                <!-- fecha inicio -->
                <b-form-group label-for="fechaInicio" label="Fecha inicio" class="col-md-2">
                  <b-form-input
                    v-on:keyup="diffDate()"
                    @blur="diffDate()"
                    type="datetime-local"
                    id="fechaInicio"
                    :max="newForm.FechaFin"
                    v-model="newForm.FechaInicio"
                    required
                  ></b-form-input>
                </b-form-group>
                <!-- fecha fin -->
                <b-form-group label-for="fechaFinal" label="" class="col-md-1 px-0 mt-3 text-center">
                  <div class="d-flex">
                    <vs-button
                      style="width: 50%"
                      icon
                      success
                       
                      @click="
                        newForm.FechaFin = newForm.FechaInicio;
                        diffDate();
                        fechaCondicion = 1;
                      "
                      type="button"
                      class="mt-3"
                      ><i class="fa-solid fa-play"></i
                    ></vs-button>
                    <vs-button
                      style="width: 50%"
                      icon
                       
                      @click="
                        ultimoDia();
                        fechaCondicion = 2;
                      "
                      type="button"
                      class="mt-3"
                      ><i class="fa-solid fa-forward-step"></i
                    ></vs-button>
                  </div>
                </b-form-group>
                <b-form-group label-for="fechaFinal" label="Fecha final" class="col-md-2">
                  <b-form-input
                    :style="fechaCondicion == '1' ? 'border: solid 2px #16a76b;' : fechaCondicion == 2 ? 'border: solid 2px #186bdd;' : ''"
                    v-on:keyup="diffDate()"
                    @blur="diffDate()"
                    type="datetime-local"
                    id="fechaFinal"
                    :min="newForm.FechaInicio"
                    v-model="newForm.FechaFin"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <!-- deducciones -->
              <div v-if="newForm.TipoNovedadId == 1" class="row">
                <div
                  class="col-md-4"
                  v-if="
                    [
                      'Sindicato',
                      'Sanción pública',
                      'Sanción privada',
                      'Libranza',
                      'Pago a terceros',
                      'Anticipos',
                      'Otras deducciones',
                      'Pensión voluntaria',
                      'Retención en la fuente',
                      'AFC',
                      'Cooperativa',
                      'Embargo fiscal',
                      'Plan complementario',
                      'Educación',
                      'Reintegro',
                      'Deuda',
                    ].includes(newForm.descripcion)
                  "
                >
                  <label v-if="['Sindicato'].includes(newForm.descripcion)" for="exampleFormControlInput1" class="form-label"> % </label>
                  <label
                    v-if="
                      [
                        'Sanción pública',
                        'Sanción privada',
                        'Libranza',
                        'Pago a terceros',
                        'Anticipos',
                        'Otras deducciones',
                        'Pensión voluntaria',
                        'Retención en la fuente',
                        'AFC',
                        'Cooperativa',
                        'Embargo fiscal',
                        'Plan complementario',
                        'Educación',
                        'Reintegro',
                        'Deuda',
                      ].includes(newForm.descripcion)
                    "
                    for="exampleFormControlInput1"
                    class="form-label"
                  >
                    Valor
                  </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="newForm.valor"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>

                <div class="col-md-4">
                  <label for="exampleFormControlInput1" class="form-label"> Costo reflejado en Nómina </label>
                  <label class="form-control">
                    {{
                      newForm.total
                        ? Number(newForm.total).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0.0
                    }}</label
                  >
                </div>
              </div>

              <!-- devengados -->

              <div class="row" v-if="newForm.TipoNovedadId == 2">
                <template v-if="['Cesantías', 'Prima salarial', 'Vacaciones disfrutadas', 'Vacaciones compensadas no disfrutadas'].includes(newForm.descripcion)">
                  <div class="col-md-4 mt-3">
                    <label for="exampleFormControlInput1" class="form-label"> Días </label>
                    <input
                      v-on:keyup="calculoNuevoItem"
                      required
                      onfocus="this.select()"
                      v-model="newForm.dias"
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-4 mt-3">
                    <label for="exampleFormControlInput1" class="form-label"> Valor </label>
                    <input
                      required
                      v-on:keyup="calculoNuevoItem"
                      onfocus="this.select()"
                      v-model="newForm.valor"
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </template>

                <template
                  v-if="
                    [
                      'Prima no salarial',
                      'Bonificación salarial',
                      'Bonificación no salarial',
                      'Auxilio salarial',
                      'Auxilio no salarial',
                      'Huelga legal',
                      'Otro concepto salarial',
                      'Otro concepto no salarial',
                      'Compensación ordinaria',
                      'Compensación extraordinaria',
                      'Bono de alimentación salarial',
                      'Bono de alimentación no salarial',
                      'Otros bonos salariales',
                      'Otros bonos no salariales',
                      'Comisión',
                      'Pago a terceros',
                      'Anticipo',
                      'Dotación',
                      'Apoyo sostenimiento',
                      'Teletrabajo',
                      'Bonificación por retiro',
                      'Indemnización',
                      'Reintegro',
                    ].includes(newForm.descripcion)
                  "
                >
                  <div class="col-md-4 mt-3">
                    <label for="exampleFormControlInput1" class="form-label"> Valor </label>
                    <input
                      required
                      v-on:keyup="calculoNuevoItem"
                      onfocus="this.select()"
                      v-model="newForm.valor"
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </template>

                <template
                  v-if="
                    [
                      'Viáticos salariales',
                      'Viáticos no salariales',
                      'Horas extras diurnas',
                      'Horas extras nocturnas',
                      'Horas extras dominicales y festivas',
                      'Horas extras nocturnas dominicales y festivas',
                      'Horas recargo nocturno',
                      'Horas recargo dominical y festiva',
                      'Hora recargo nocturno dominical y festivos',
                    ].includes(newForm.descripcion)
                  "
                >
                  <div class="col-md-4">
                    <label
                      v-if="['Viáticos salariales', 'Viáticos no salariales', 'Vacaciones compensadas no disfrutadas'].includes(newForm.descripcion)"
                      for="exampleFormControlInput1"
                      class="form-label"
                    >
                      Valor
                    </label>
                    <label
                      v-else-if="
                        [
                          'Horas extras diurnas',
                          'Horas extras nocturnas',
                          'Horas extras dominicales y festivas',
                          'Horas extras nocturnas dominicales y festivas',
                          'Horas recargo nocturno',
                          'Horas recargo dominical y festiva',
                          'Hora recargo nocturno dominical y festivos',
                        ].includes(newForm.descripcion)
                      "
                      for="exampleFormControlInput1"
                      class="form-label"
                    >
                      Horas
                    </label>
                    <input
                      onClick="this.select();"
                      :readonly="!['Viáticos salariales', 'Viáticos no salariales', 'Vacaciones compensadas no disfrutadas'].includes(newForm.descripcion)"
                      required
                      v-on:keyup="calculoNuevoItem"
                      onfocus="this.select()"
                      v-model="newForm.valor"
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>

                  <div class="col-md-4 mt-4 text-center" v-if="['Horas recargo dominical y festiva'].includes(newForm.descripcion)">
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta = 0.75;
                          calculoNuevoItem();
                          valporcenta = 1;
                        "
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio1"
                        :checked="valporcenta == 1"
                      />
                      <label class="form-check-label" for="radio1">75 %</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta = 1.75;
                          calculoNuevoItem();
                          valporcenta = 2;
                        "
                        :checked="valporcenta == 2"
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio2"
                      />
                      <label class="form-check-label" for="radio2">175 %</label>
                    </div>
                  </div>

                  <div class="col-md-4 mt-4 text-center" v-if="['Hora recargo nocturno dominical y festivos'].includes(newForm.descripcion)">
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta2 = 1.1;
                          calculoNuevoItem();
                          valporcenta2 = 1;
                        "
                        class="form-check-input"
                        type="radio"
                        name="flradio2"
                        id="radio3"
                        :checked="valporcenta2 == 1"
                      />
                      <label class="form-check-label" for="radio3">110 %</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta2 = 2.1;
                          calculoNuevoItem();
                          valporcenta2 = 2;
                        "
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio4"
                        :checked="valporcenta2 == 2"
                      />
                      <label class="form-check-label" for="radio4">210 %</label>
                    </div>
                  </div>
                </template>

                <template
                  v-if="
                    [
                      'Incapacidad común',
                      'Incapacidad profesional',
                      'Incapacidad laboral',
                      'Licencia de maternidad o paternidad',
                      'Licencia remunerada',
                      'Licencia no remunerada',
                    ].includes(newForm.descripcion)
                  "
                >
                  <div class="col-md-4">
                    <label for="exampleFormControlInput1" class="form-label"> Días </label>
                    <input
                      required
                      v-on:keyup="calculoNuevoItem"
                      onfocus="this.select()"
                      v-model="newForm.dias"
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                      :readonly="['Licencia de maternidad o paternidad', 'Licencia remunerada', 'Licencia no remunerada'].includes(newForm.descripcion)"
                    />
                  </div>

                  <div class="col-md-4 mt-4 text-center" v-if="['Incapacidad común'].includes(newForm.descripcion)">
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta3 = 1;
                          calculoNuevoItem();
                          valporcenta3 = 1;
                        "
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio1"
                        :checked="valporcenta3 == 1"
                      />
                      <label class="form-check-label" for="radio1">100 %</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta3 = 0.666;
                          calculoNuevoItem();
                          valporcenta3 = 2;
                        "
                        :checked="valporcenta3 == 2"
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio2"
                      />
                      <label class="form-check-label" for="radio2">66.6 %</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        @change="
                          porcenta3 = 0.5;
                          calculoNuevoItem();
                          valporcenta3 = 3;
                        "
                        :checked="valporcenta3 == 3"
                        class="form-check-input"
                        type="radio"
                        name="flradio1"
                        id="radio2"
                      />
                      <label class="form-check-label" for="radio2">50 %</label>
                    </div>
                  </div>
                </template>

                <div class="col-md-4">
                  <label for="exampleFormControlInput1" class="form-label"> Costo reflejado en Nómina </label>
                  <label class="form-control">
                    {{
                      newForm.total
                        ? Number(newForm.total).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0.0
                    }}</label
                  >
                </div>
                <!-- observaciones -->
                <b-form-group label-for="observaciones" label="Observaciones" class="col-md-12">
                  <b-form-textarea id="observaciones" v-model="newForm.Observaciones" rows="3" max-rows="12"></b-form-textarea>
                </b-form-group>
              </div>

              <div class="w-100 d-flex justify-content-center">
                <b-button type="submit" class="mt-3 w-30" variant="outline-success"><i class="fas fa-check"></i> Registrar</b-button>
              </div>
            </b-form>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// import codigosCIE10 from "./optionsCodigoCIE10";
// import EPSs from "./EPSs";
const Swal = require("sweetalert2");
import { core } from "../../../../config/pluginInit";

export default {
  name: "DataTable",
  data() {
    return {
      fechaCondicion: 0,
      worker: {
        searching: false,
        imputSearchWorker: null,
        stateErrorWorker: null,
        about: null,
      },
      hasForm: false,
      tipoEv: null,
      tipoDeEvento: [
        { value: 1, text: "Deducción" },
        { value: 2, text: "Devengado" },
      ],
      formDataBase: {
        codigosCIE10: null,
        EPSs: null,
      },
      newForm: {
        FechaInicio: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DD HH:mm"),
        FechaFin: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DD HH:mm"),
      },
      incapacidades: [],
      //////////////
      listaDeducciones: [],
      listaDevengados: [],
      usuario: {},
      //nuevoItem: {},
      porcenta: 0.75,
      porcenta2: 1.1,
      porcenta3: 1,
      valporcenta: 1,
      valporcenta2: 1,
      valporcenta3: 1,
      nominaLiquidacionPersonaDetalle: [],
      timeout: null,
      id: null,
      nominaConfiguracion: {},
    };
  },

  async mounted() {
    //   {
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
    //   },
    // }
    // ();
    this.id = this.$route.params.Id;
    // alert(this.worker.imputSearchWorker);
    this.searchingWorker();
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getNominaConfiguracion();
  },
  methods: {
    ultimoDia() {
      const getLastDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let FechaFin = getLastDayOfMonth(new Date(this.newForm.FechaInicio));
      this.newForm.FechaFin = this.$moment(new Date(FechaFin)).format("YYYY-MM-DDTHH:mm");
      this.diffDate();
    },
    async getNominaConfiguracion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaConfiguracion/GetNominaConfiguracion/",
        });
        this.nominaConfiguracion = res;
      } catch (error) {
        this.nominaConfiguracion = {};
        console.log("err", error);
      }
    },
    editRow(it) {
      console.log("createform -->", it);

      this.createForm();
      this.newForm.TipoNovedadId = it.tipoNovedadId;
      this.newForm.conceptoId = it.conceptoId;
      this.newForm.FechaInicio = it.fechaInicio;
      this.newForm.FechaFin = it.fechaFin;
      this.newForm.valor = it.baseLiquida;
      this.calculoNuevoItem();
      this.diffDate();
    },
    async deleteRow(item) {
      try {
        console.log(item);
        Swal.fire({
          title: "¿Seguro eliminar item?",
          text: "Después de eliminarlo no podrás recuperarlo!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$isLoading(true);
              //let loader = this.$loading.show();

              let res = await this.$store.dispatch("hl_delete", {
                path: "NominaLiquidacion/DelNominaLiquidacionPersonaItem/",
                data: item,
              });
              // if (res) {
              console.log(res);
              if (res.status == 400) {
                this.$isLoading(false);
                Swal.fire("Error!", "Intentalo nuevamente.", "error");
              } else {
                await this.initNovedades(this.id);
                this.$isLoading(false);
                Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
                this.$forceUpdate();
              }
            } catch (error) {
              this.$isLoading(false);
              Swal.fire("Error!", "Intentalo nuevamente.", "error");
              console.log("error", error);
            }
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    async guardarDataUsuario() {
      try {
        await this.agregarNuevoItem();
        this.$isLoading(true);
        let dataUsuario = this.nominaLiquidacionPersonaDetalle[0];
        console.log("this.nominaLiquidacionPersonaDetalle ", dataUsuario);
        console.log("this.newForm. ", this.newForm);
        dataUsuario.Id = null;
        dataUsuario.nominaLiquidacionPersonaId = null;
        dataUsuario.TipoNovedadId = this.newForm.TipoNovedadId;
        dataUsuario.FechaInicio = this.newForm.FechaInicio;
        dataUsuario.FechaFin = this.newForm.FechaFin;
        dataUsuario.Horas = this.newForm.Horas;
        dataUsuario.PersonaIdRegistro = this.usuario.id;
        dataUsuario.Observaciones = this.newForm.Observaciones;
        dataUsuario.SalarioPersona = this.worker.about.salario;
        dataUsuario.PersonaId = this.worker.about.id;
        dataUsuario.FechaRegistro = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm:ss");
        console.log("this.dataUsuario ", dataUsuario);

        let res = await this.$store.dispatch("hl_post", {
          path: "NominaLiquidacion/NominaLiquidacionPersonaDetalle",
          data: dataUsuario,
        });
        // itnlpd.id == res;
        console.log("res---->*/*", res);
        //}

        this.$isLoading(false);
        this.nominaLiquidacionPersonaDetalle = [];
        Swal.fire("Muy bien!", "Datos almacenados correctamente.", "success");
        await this.initNovedades(this.id);
        // core.index();
        // window.$("#datatablenovedades").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        this.$forceUpdate();
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", "Intentalo nuevamente.", "error");
        console.log("error", error);
      }
    },
    async calcularHoras() {
      let fechaFin = this.$moment(this.newForm.FechaFin);
      var diferenciaEnMilisegundos = fechaFin.diff(this.newForm.FechaInicio);

      var horas = this.$moment.duration(diferenciaEnMilisegundos).asHours();
      this.newForm.dias = this.$moment.duration(diferenciaEnMilisegundos).asDays() + 1;
      return horas.toFixed(2);
    },
    calculoNuevoItem() {
      setTimeout(() => {
        if (["Cesantías", "Prima salarial"].includes(this.newForm.descripcion)) {
          this.newForm.total = (this.newForm.dias * this.newForm.valor) / 360;
          // this.newForm.total = this.newForm.total.toLocaleString("es");
          this.$forceUpdate();
        } else {
          if (["Viáticos salariales", "Viáticos no salariales", "Bonificación salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor;
            this.$forceUpdate();
          }
          if (["Horas extras diurnas"].includes(this.newForm.descripcion)) {
            let total = 0;
            //abr 26-05-2024
            //modificación horasmes, por ley aprobada a partir del 2023 se reduce 1 h x año
            // 15 de julio de 2023 1 hora 47 horas semanales
            // 15 de julio de 2024 1 hora 46 horas semanales
            // 15 de julio de 2025 2 horas 44 horas semanales
            // 15 de julio de 2026 2 horas 42 horas semanales
            // let horasMes = 240;

            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let precioHora = this.worker.about.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.25;
            total = (porcentajeAdicional + precioHora) * this.newForm.valor;
            this.newForm.total = total.toFixed(2);
            this.$forceUpdate();
            console.log("configuracion ", this.nominaConfiguracion.horasMaximasSemanales);
          }
          if (["Horas extras nocturnas"].includes(this.newForm.descripcion)) {
            let total = 0;
            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            // let horasMes = 240;
            let precioHora = this.worker.about.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.75;
            total = (porcentajeAdicional + precioHora) * this.newForm.valor;

            this.newForm.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras dominicales y festivas"].includes(this.newForm.descripcion)) {
            let total = 0;
            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            // let horasMes = 240;
            let precioHora = this.worker.about.salario / horasMes;
            let porcentajeAdicional = precioHora * 1;
            total = (porcentajeAdicional + precioHora) * this.newForm.valor;

            this.newForm.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras nocturnas dominicales y festivas"].includes(this.newForm.descripcion)) {
            let total = 0;
            // let horasMes = 240;
            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let precioHora = this.worker.about.salario / horasMes;
            let porcentajeAdicional = precioHora * 1.5;
            total = (porcentajeAdicional + precioHora) * this.newForm.valor;

            this.newForm.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas recargo nocturno"].includes(this.newForm.descripcion)) {
            let total = 0;
            // let horasMes = 240;
            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let precioHora = this.worker.about.salario / horasMes;
            total = precioHora * this.newForm.valor * 0.35;
            this.newForm.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Horas recargo dominical y festiva"].includes(this.newForm.descripcion)) {
            let total = 0;
            // let horasMes = 240;
            // let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let precioHora = this.worker.about.salario / horasMes;
            total = precioHora * this.newForm.valor * this.porcenta;

            // let precioHora = this.worker.about.salario / horasMes;
            // let porcentajeAdicional = precioHora * this.porcenta;
            // total = (porcentajeAdicional + precioHora) * this.newForm.valor;

            this.newForm.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Hora recargo nocturno dominical y festivos"].includes(this.newForm.descripcion)) {
            let total = 0;
            // let horasMes = 240;
            // let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            // let precioHora = this.worker.about.salario / horasMes;
            // let porcentajeAdicional = precioHora * this.porcenta2;
            // total = (porcentajeAdicional + precioHora) * this.newForm.valor;

            let horasMes = (this.nominaConfiguracion.horasMaximasSemanales / 6) * 30;
            let precioHora = this.worker.about.salario / horasMes;
            total = precioHora * this.newForm.valor * this.porcenta2;

            this.newForm.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones disfrutadas"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.newForm.dias * this.worker.about.salario) / 30;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones compensadas no disfrutadas"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.newForm.dias * this.worker.about.salario) / 30;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Prima no salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad común"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.worker.about.salario / 30) * this.newForm.dias * this.porcenta3;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad profesional"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.worker.about.salario / 30) * this.newForm.dias;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad laboral"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.worker.about.salario / 30) * this.newForm.dias;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia de maternidad o paternidad"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.worker.about.salario / 30) * this.newForm.dias;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            console.log("el total ", this.newForm.total);
            this.$forceUpdate();
          }
          if (["Licencia remunerada"].includes(this.newForm.descripcion)) {
            this.newForm.total = (this.worker.about.salario / 30) * this.newForm.dias;
            // this.newForm.total = this.newForm.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia no remunerada"].includes(this.newForm.descripcion)) {
            this.newForm.total = 0;
            this.$forceUpdate();
          }
          if (["Bonificación salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación no salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio no salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Huelga legal"].includes(this.newForm.descripcion)) {
            //falta verificar como se calcula
            this.newForm.total = 0;
            this.$forceUpdate();
          }
          if (["Otro concepto salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otro concepto no salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación ordinaria"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación extraordinaria"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación no salarial"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos salariales"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos no salariales"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Comisión"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipo"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Dotación"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Apoyo sostenimiento"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Teletrabajo"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación por retiro"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Indemnización"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          //deducciones
          if (["Sindicato"].includes(this.newForm.descripcion)) {
            // this.newForm.total = (this.worker.about.salario / 30) * (this.newForm.valor * 0.01);
            this.newForm.total = this.worker.about.salario * this.newForm.valor;
            this.newForm.total = this.newForm.total / 100;
            //this.newForm.total = this.newForm.total; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción pública"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción privada"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Libranza"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipos"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otras deducciones"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pensión voluntaria"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Retención en la fuente"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["AFC"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Cooperativa"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Embargo fiscal"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Plan complementario"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Educación"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Deuda"].includes(this.newForm.descripcion)) {
            this.newForm.total = this.newForm.valor; //.toLocaleString("es");
            this.$forceUpdate();
          }
        }
        console.log("cal", this.newForm.total);
      }, 1000);
    },
    obtenerDescripcionItem(tipo, itemId) {
      if (tipo == "devengado") {
        let res = this.listaDevengados.find((x) => x.id == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDevengados -", this.listaDevengados);
        return res.descripcion;
      } else {
        let res = this.listaDeducciones.find((x) => x.id == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDeducciones -", this.listaDeducciones);
        return res.descripcion;
      }
    },
    async selecionarItem(e, tipo) {
      try {
        this.newForm.tipo = tipo;
        this.newForm.itemId = e;
        this.newForm.dias = 0;
        this.newForm.valor = 0;
        this.newForm.total = 0;
        this.porcenta = 0.75;
        this.porcenta2 = 1.1;
        this.porcenta3 = 1;
        this.valporcenta = 1;
        this.valporcenta2 = 1;
        this.valporcenta3 = 1;
        this.newForm.descripcion = this.obtenerDescripcionItem(tipo, this.newForm.itemId);
        console.log("---------- nuevo item ", this.newForm);
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async agregarNuevoItem() {
      try {
        if (this.newForm.TipoNovedadId == 2) {
          // //DEVENGADOS
          let itDevengados = this.listaDevengados.find((x) => x.id == this.newForm.conceptoId);
          console.log("this.newForm ", this.newForm);
          console.log("item itdevengado ", itDevengados);
          switch (itDevengados.descripcion) {
            case "Cesantías":
              if (this.worker.about.salario == this.nominaConfiguracion.salarioMinimo) {
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDevengados.descripcion,
                  conceptoId: itDevengados.id,
                  baseLiquida: this.worker.about.salario + this.nominaConfiguracion.auxilioDeTransporte,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: this.nominaConfiguracion.cesantias,
                  valorEmpleador: (((this.worker.about.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.cesantias) / 100).toFixed(2),
                  dias: this.newForm.dias,
                  valor: this.newForm.valor,
                  tDev: this.newForm.total,
                  tDed: 0,
                });
                // this.newForm = {};
              }
              break;
            case "Prima salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.worker.about.salario + this.nominaConfiguracion.auxilioDeTransporte,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: this.nominaConfiguracion.primaDeServicios,
                valorEmpleador: (((this.worker.about.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.primaDeServicios) / 100).toFixed(2),
                dias: this.newForm.dias,
                valor: this.newForm.valor,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Viáticos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.valor,
                tDev: this.newForm.valor,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Viáticos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.valor,
                tDev: this.newForm.valor,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas extras diurnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0.25,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas extras nocturnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0.75,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas extras dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 1,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas extras nocturnas dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 1.5,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas recargo nocturno":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0.35,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Horas recargo dominical y festiva":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: this.porcenta,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Hora recargo nocturno dominical y festivos":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: this.porcenta2,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.total,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Vacaciones disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: this.newForm.valor,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Vacaciones compensadas no disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: this.newForm.valor,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Prima no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.newForm.valor,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Incapacidad común":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Incapacidad profesional":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Incapacidad laboral":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Licencia de maternidad o paternidad":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Licencia remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Licencia no remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Bonificación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Bonificación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              // this.newForm = {};

              break;
            case "Auxilio salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              this.newForm = {};

              break;
            case "Auxilio no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Huelga legal":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Otro concepto salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Otro concepto no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Compensación ordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Compensación extraordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Bono de alimentación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Bono de alimentación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Otros bonos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Otros bonos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Comisión":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Pago a terceros":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Anticipo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Dotación":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Apoyo sostenimiento":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Teletrabajo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Bonificación por retiro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Indemnización":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
            case "Reintegro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.descripcion,
                conceptoId: itDevengados.id,
                baseLiquida: this.newForm.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.newForm.dias,
                valor: 0,
                tDev: this.newForm.total,
                tDed: 0,
              });
              //this.newForm = {};

              break;
          }
        } else {
          console.log("newForm in agregarnewForm", this.newForm);
          if (this.newForm.tipo == "deduccion") {
            console.log("es deduccion.....");
            let itDeduccion = this.listaDeducciones.find((x) => x.id == this.newForm.conceptoId);
            console.log("this.newForm ", this.newForm);
            console.log("item itdevengado ", itDeduccion);
            switch (itDeduccion.descripcion) {
              case "Sindicato":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Sanción pública":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Sanción privada":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Libranza":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Pago a terceros":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Anticipos":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Otras deducciones":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Pensión voluntaria":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Retención en la fuente":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "AFC":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Cooperativa":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Embargo fiscal":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Plan complementario":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Educación":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Reintegro":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
              case "Deuda":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.descripcion,
                  conceptoId: itDeduccion.id,
                  baseLiquida: this.newForm.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.newForm.valor,
                  tDed: this.newForm.total,
                  tDev: 0,
                });
                //this.newForm = {};

                break;
            }
          }
        }
        // this.actualizaCalculosEmpleadoSel();
        // /////////////
        // await this.guardarDataUsuario();
        // await this.actualizaTotalesLiquida();

        ////////////
        // await this.guardarDataUsuario();
        // this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    actualizaNovedad(e) {
      console.log(e);
      this.newForm.EventualidadId = e;
    },
    async diffDate() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.newForm.FechaFin && this.newForm.FechaInicio) {
          const days = (new Date(this.newForm.FechaFin).getTime() - new Date(this.newForm.FechaInicio).getTime()) / (1000 * 60 * 60 * 24);
          this.newForm.Dias = (days + 1).toFixed(0);
          //this.newForm.dias
          this.newForm.Horas = await this.calcularHoras();
          let exclusiones = [
            "Viáticos salariales",
            "Viáticos no salariales",
            "Bonificación salarial",
            "Bonificación no salarial",
            "Auxilio salarial",
            "Auxilio no salarial",
            "Huelga legal",
            "Otro concepto salarial",
            "Otro concepto no salarial",
            "Compensación ordinaria",
            "Compensación extraordinaria",
            "Bono de alimentación salarial",
            "Bono de alimentación no salarial",
            "Otros bonos salariales",
            "Otros bonos no salariales",
            "Comisión",
            "Pago a terceros",
            "Anticipo",
            "Dotación",
            "Apoyo sostenimiento",
            "Teletrabajo",
            "Bonificación por retiro",
            "Indemnización",
            "Reintegro",
            "Sindicato",
            "Sanción pública",
            "Sanción privada",
            "Libranza",
            "Pago a terceros",
            "Anticipos",
            "Otras deducciones",
            "Pensión voluntaria",
            "Retención en la fuente",
            "AFC",
            "Cooperativa",
            "Embargo fiscal",
            "Plan complementario",
            "Educación",
            "Reintegro",
            "Deuda",
            "Fondo de subsistencia",
            "Prima salarial",
            "Prima no salarial",
          ];
          console.log("exclusiones", this.newForm.descripcion);
          console.log("exclusiones", !exclusiones.includes(this.newForm.descripcion));
          if (!exclusiones.includes(this.newForm.descripcion)) {
            console.log("calculando horas");
            this.newForm.valor = await this.calcularHoras();
          }

          this.calculoNuevoItem();
        } else {
          //this.newForm.dias = 0
          this.newForm.Dias = 0;
          this.newForm.Horas = 0;
          this.newForm.valor = 0;
        }
        this.$forceUpdate();
      }, 1000);
    },
    async getDevengados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Devengado/GetDevengados/",
        });

        let excluir = ["Salario", "Auxilio de transporte", "Incapacidad común", "Incapacidad laboral", "Incapacidad profesional"];
        //let nuevaLista = [];
        this.listaDevengados = res.filter((x) => !excluir.includes(x.descripcion));
        console.log("list devengados", this.listaDevengados);
      } catch (error) {
        this.listaDevengados = {};
        console.log("err", error);
      }
    },
    async getDeducciones() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Deduccion/GetDeducciones/",
        });
        console.log("list DEDUCCIONES **************", res);
        let excluir = ["Salud", "Fondo de pensión", "Fondo de solidaridad pensional"];
        this.listaDeducciones = res.filter((x) => !excluir.includes(x.descripcion));

        //this.listaDeducciones = res;
        console.log("list DEDUCCIONES **************", this.listaDeducciones);
      } catch (error) {
        this.listaDeducciones = {};
        console.log("err", error);
      }
    },
    searchingWorker() {
      this.worker.searching = true;
      this.$store
        .dispatch("get", {
          path: `Persona/GetPersonaById/${this.id}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            result = result.traceId ? null : result;
            if (result) {
              resolve(result);
            } else {
              reject("Trabajador no encontrado");
            }
          });
        })
        .then(async (result) => {
          this.worker.stateErrorWorker = null;
          this.worker.about = result;
          //console.log("worker --->", this.worker.about);
          await this.getDevengados();
          await this.getDeducciones();
          await this.initNovedades(this.id);
          core.index();
          window.$("#datatablenovedades").DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          this.worker.stateErrorWorker = false;
          this.worker.about = null;
          console.error(error);
        })
        .finally(() => {
          this.worker.searching = false;
        });
    },
    async initNovedades(personaId) {
      let result = await this.$store.dispatch("get", {
        path: `NominaLiquidacion/GetNominaLiquidacionPersonaDetalle/${personaId}`,
      });
      let resultadoFinal = [];
      for await (let item of result) {
        console.log("listitem ", item);
        //deducción
        if (item.tipoNovedadId == 1) {
          item.novedad = this.listaDeducciones.find((x) => x.id == item.conceptoId);
          console.log("list deduccion item novedad", item.novedad);
          if (item.novedad) {
            item.novedad = item.novedad ? item.novedad.descripcion : "-";
            resultadoFinal.push(item);
          }
        } else {
          console.log("item.conceptoId 0", item.conceptoId);
          item.novedad = this.listaDevengados.find((x) => x.id == item.conceptoId);
          console.log("list devengados item novedad", item.novedad);
          if (item.novedad) {
            item.novedad = item.novedad ? item.novedad.descripcion : "--";
            resultadoFinal.push(item);
          }
          //devengado
        }
      }
      console.log("resultnovedades --->", result);
      this.incapacidades = resultadoFinal;
    },

    initNewForm() {
      this.newForm = {
        PersonaId: null,
        TipoId: null,
        EventualidadId: null,
        FechaInicio: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDT00:00"),
        FechaFin: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDT00:00"),
        Importe: null,
        Dias: null,
        FechaRegistro: null,
        Descripcion: null,
      };
    },
    createForm() {
      this.hasForm = true;
      this.initNewForm();
    },
    cancelForm() {
      this.hasForm = false;
    },
    async handleForm() {
      //post
      //    this.agregarnewForm

      try {
        this.newForm.TipoId = this.tipoEv;
        this.newForm.PersonaId = this.worker.about.id;
        this.newForm.FechaRegistro = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.newForm.FechaInicio = this.$moment.tz(this.newForm.FechaInicio, "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.newForm.FechaFin = this.$moment.tz(this.newForm.FechaFin, "America/Bogota").format("YYYY-MM-DDTHH:mm");

        await this.$store.dispatch("post", {
          path: "NominaLiquidacion/NominaLiquidacionNovedad",
          data: this.newForm,
        });
        Swal.fire({
          icon: "success",
          title: "Novedad registrada",
          showConfirmButton: false,
          timer: 1500,
        });
        // this.initIncapacidades(this.worker.about.id);
        this.initNewForm();
        this.hasForm = false;

        //getAll
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  computed: {
    salarioBaseDia() {
      return Number.parseFloat(this.newForm.salarioBase / 30).toFixed(2);
    },
  },
  filters: {
    formatDate() {
      //this.$moment.tz(value, "America/Bogota").format("YYYY-MM-DDTHH:mm");
      //formate fecha de la forma 2020-12-31T00:00:00 a 31/12/2020
      // return value.split("T")[0].split("-").reverse().join("/");
    },
  },
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0px;
}
</style>
